import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingBg from "@images/slider-img1.jpg"
import PageHeading from "@components/page-heading"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const WaterActivity = () => {
  return (
    <Layout>
      <SEO title="Water Activity" description="" />
      <PageHeading heading="Water Activity" BgImg={HeadingBg} />
      <div className="page-section">
        <Container>
          <Row>
            <Col lg="7" className="d-flex align-items-center">
              {" "}
              <div>
                <h3 className="my-0 sub-heading">WATER ACTIVITY ANALYSIS</h3>
                <h2 className="mt-0 mb-4">Measure stability</h2>
                {/* <p className="font-weight-bold">
                  Water activity provides the basis of shelf stability and is
                  considered a critical control point for preventing microbial
                  contamination in edible products or chemical reactions in
                  biomass. The resulting measurement ranges from 0.00 Aw/g
                  (completely dry sample) to 1.00 Aw/g (pure water).
                </p> */}
                {/* <p>
                  Water activity (Aw) is a measure of energy, it’s a ratio
                  between the vapor pressure of the sample and the surrounding
                  enclosed air. Water activity is measured with an water
                  activity meter that has a chilled-mirror dew point sensor.
                  Unlike other water activity sensors, the dew point sensor
                  measures water activity directly. Samples are enclosed in a
                  sealed container, then the relative humidity in the headspace
                  will equalize with the water activity of the sample, the
                  relative humidity of the air in the headspace in measured to
                  determine the water activity.
                </p> */}
                 <p>
                 One of the earliest methods of food preservation is the control of water in foods. This preservation technique limits the availability of water for microbial growth and biochemical reactions. The degree of availability of water in food is commonly expressed as the water activity (aw). Thermodynamically, water activity (aw) is a measure of the energy status of water in a system.

                </p>
                <p>
                The degree to which water is structurally bound, and the degree to which it is freely available, or the degree to which it is chemically bound and unavailable can all be established by an ability to specify the water activity of cannabis products.


                </p>
                <p>
                Higher aw can profoundly impact revenue potential through failed batches and rework.



                </p>
                <p>
                By measuring and controlling aw it is possible to predict safety and stability of a product. It can be utilized to optimize physical properties, maintain or minimize chemical and biochemical reactivity, and predict microbial growth.



                </p>
                <p>
                State laws and requirements, sample size submission, TAT, instrumentation and any special consideration.




                </p>
              </div>
            </Col>

            <Col lg="5" className="d-flex align-items-center">
              <StaticImage
                src="../../images/terpene-page-img.jpg"
                alt="Terpenes Testing"
                quality={100}
                style={{ borderRadius: "10px" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="downloads text-center">
        <Container>
          {" "}
          <h2 className="mt-0 mb-1 text-center">
            <span className="text-white">
              LET'S TALK ABOUT YOUR TESTING NEEDS
            </span>
          </h2>
          <h4 className="text-white text-center mt-2 mb-5">
            Our experts are always ready to answer your questions.
          </h4>
          <Link className="btn" to="/contact">
            Let's Talk
          </Link>
        </Container>
      </div>
    </Layout>
  )
}

export default WaterActivity
